.header {
  background-color: #f1f1f1;
  padding: 30px;
  text-align: center; }

.content {
  padding: 16px; }

.sticky + .content {
  padding-top: 60px; }

.test {
  background-color: white;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); }

.epaper-thumb {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
  margin: 0px 20px 20px 0px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  min-height: 550px;
  max-height: 550px;
  overflow: hidden;
  background-color: white;
  border: 6px solid #e6e6e6; }

.btn-group {
  padding: 10px 0px;
  font-weight: 600; }
  .btn-group button {
    background-color: white;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    color: black;
    padding: 10px 24px;
    cursor: pointer;
    font-weight: 500;
    float: left; }
    .btn-group button i {
      font-weight: 600; }
  .btn-group .btn-f {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  .btn-group .btn-l {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }
  .btn-group .page-btn {
    padding: 11px 24px; }

.btn-group:after {
  content: "";
  clear: both;
  display: table; }

.btn-group button:not(:last-child) {
  border-right: none;
  /* Prevent double borders */ }

/* Add a background color on hover */
.btn-group button:hover {
  background-color: rgba(0, 0, 0, 0.1); }

.e-paper1 {
  margin-bottom: 20px; }
  .e-paper1 .paper-date {
    margin-left: 10px;
    font-size: 15px;
    color: #666;
    margin: 0px; }
  .e-paper1 .thumb-e {
    min-height: 600px;
    max-height: 600px; }

.loading {
  position: fixed;
  z-index: 1000001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.2); }
  .loading .loading-c {
    position: fixed;
    top: 50%;
    left: 50%; }

.shimmer-wrapper {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px; }
  .shimmer-wrapper .shimmer-w {
    width: 60% !important; }
  .shimmer-wrapper .shimmer {
    padding: 10px;
    background-color: #fff; }

.container-body {
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding-left: 0px;
  padding-right: 0px;
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important; }

.main-container {
  margin-top: 70px; }

.shownavbar {
  position: fixed;
  top: 1px; }

.shownavbar1 {
  background-color: white;
  top: 0; }
  .shownavbar1 .second-nav1 {
    position: fixed;
    top: 0; }

.second-nav {
  margin-top: 43px;
  background-color: #fff;
  display: flex;
  z-index: 10000;
  width: 100%;
  margin-bottom: -72px;
  overflow-x: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); }
  .second-nav a {
    float: left;
    color: #6b717b;
    text-align: center;
    padding: 6px 13px 9px 13px;
    font-size: 14px !important;
    font-weight: 500;
    text-decoration: none;
    font-size: 17px; }
  .second-nav a:hover {
    background-color: #f5f5f5;
    color: black; }
  .second-nav a:active {
    background-color: #f5f5f5;
    color: black; }

.second-nav1-w {
  background-color: #f2f2f2;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  z-index: 10000;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); }
  .second-nav1-w .second-nav1 {
    margin-top: 35px;
    background-color: #f2f2f2;
    display: flex;
    z-index: 10000;
    width: 100%;
    margin-bottom: -36px;
    overflow-x: auto; }
    .second-nav1-w .second-nav1 a {
      float: left;
      color: #1C4B79;
      font-weight: bold;
      text-align: center;
      line-height: 20px;
      padding: 14px 10px 14px 10px;
      border-bottom: 4px solid #f2f2f2;
      text-decoration: none;
      font-size: 16px !important; }
    .second-nav1-w .second-nav1 a:hover {
      border-bottom: 4px solid #b00000; }
    .second-nav1-w .second-nav1 a.active {
      border-bottom: 4px solid #b00000; }

.sp-topnav {
  overflow: hidden;
  background-color: #5A3092;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4); }

.nav-items a {
  float: left;
  color: white;
  text-align: center;
  padding: 6px 13px 9px 13px;
  font-weight: 500;
  text-decoration: none;
  font-size: 17px; }

.nav-items a:hover {
  color: white; }

.nav-items a.active {
  color: white; }

.sp-topnav-right {
  float: right; }

.profile-pict {
  height: 29px;
  width: 29px;
  border-radius: 50%;
  margin: 3px;
  padding: 0px;
  float: left; }

.user-div {
  float: right; }

.show {
  display: block !important; }

.hide {
  display: none !important; }

.nav-item {
  margin-left: 20px;
  padding-top: 0px; }

.nav-item-icons {
  padding-top: 3px; }

.header-top {
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  display: inline-block;
  width: 100%;
  z-index: 100;
  top: 0; }

.header-title {
  float: none;
  position: absolute;
  top: 10px;
  left: 50px; }

.navbar-top-items {
  overflow: hidden;
  background-color: #333;
  top: 0; }

.navbar-top-items li {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px; }

.navbar-top-items a:hover {
  background: #ddd;
  color: black; }

.profile-pic {
  height: 49px;
  width: 49px;
  border-radius: 50%;
  margin: 0px;
  padding: 0px;
  float: right;
  margin: 10px;
  margin-top: 5px; }

img.profile-pic:hover div.dropdown-content {
  display: block !important; }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1900;
  right: 16px;
  top: 68px; }

.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  border: 0px;
  background-color: white;
  width: 100%;
  text-align: left;
  display: block; }

.dropdown-content button:hover {
  background-color: #ddd; }

.d {
  text-align: center; }

.header-text {
  width: auto;
  border: 1px solid black; }

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 101;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
  background-color: white; }

.sp-btn-login {
  margin-top: 0.1rem;
  color: white;
  background-color: #e12829;
  border: none; }
  .sp-btn-login .ms-Button-textContainer {
    margin-top: -4px; }

.sp-btn-login:hover {
  background-color: #ca1c1c;
  color: white; }

.sp-btn-login:focus {
  background-color: #ca1c1c;
  color: white;
  border: 0px;
  outline: 0; }

.sp-btn-login1 {
  margin-top: 4px;
  color: white;
  background-color: #e12829;
  border: none; }

.sp-btn-login1:hover {
  background-color: #ca1c1c;
  color: white; }

.sp-btn-login1:focus {
  background-color: #ca1c1c;
  color: white;
  border: 0px;
  outline: 0; }

.sidenav a.nav-item-link {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 20px;
  color: #2d4756;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.closebtn {
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 36px !important; }

.closebtn:hover {
  cursor: pointer; }

.sidenav a.nav-item-link:hover {
  color: white;
  background-color: #355364; }

@media (min-width: 481px) {
  .sp-toggle-btn {
    display: none !important; }
  .sp-sidenav {
    display: none !important; }
  .sp-sidenav-wrapper {
    display: none !important; } }

.topnav {
  overflow: hidden;
  background-color: #e9e9e9; }

.topnav-items {
  margin-left: 100px; }

.topnav-items a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px; }

.topnav-items a:hover {
  background-color: #ddd;
  color: black; }

.topnav-items a.active {
  background-color: #2196f3;
  color: white; }

.topnav-right {
  float: right;
  padding: 6px;
  margin-top: 8px;
  border: none;
  font-size: 17px; }

.user-name {
  float: left;
  margin: 0px;
  margin-top: 4px;
  color: #fff; }

.user-actions {
  text-align: center; }

.user-actions p {
  padding: 8px;
  margin: 0px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 13px;
  cursor: pointer; }

.user-actions hr {
  margin: 4px; }

.user-actions p:hover {
  background-color: #e12829;
  color: #fff; }

.profile-info .avatar-2 {
  height: auto;
  width: 70%;
  margin: auto;
  display: block;
  border-radius: 5% !important; }

.profile-info p {
  text-align: center; }

.sp-sidenav-wrapper {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
  position: fixed;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  height: 100%; }

.sp-sidenav {
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-top: 41px; }

.sp-show-side {
  width: 180px !important;
  border-right: 1px solid rgba(0, 0, 0, 0.5); }

.sp-sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 17px;
  color: #818181;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.sp-sidenav a:hover {
  color: #f1f1f1;
  background-color: #e12829; }

.sp-toggle-btn {
  font-size: 25px;
  color: #fff;
  cursor: pointer;
  display: block;
  float: left; }

.sp-closebtn {
  right: 25px;
  padding: 8px !important;
  height: 41px;
  background-color: #e12829;
  color: #fff; }
  .sp-closebtn span {
    font-size: 36px;
    margin-top: -19px; }

@media (max-width: 481px) {
  .sp-logo {
    height: 100px; }
  .main-container {
    margin-top: 80px; }
  .profile-info .avatar-2 {
    height: auto;
    width: 50%;
    border-radius: 5% !important; } }

.sp-logo-text {
  font-size: 30px;
  top: 20px;
  margin-left: 10px;
  color: #FFAF20;
  position: absolute;
  font-weight: bold; }

.admin-container {
  background-color: white;
  margin-top: 100px; }
  .admin-container .left-navbar {
    height: 100%;
    position: fixed; }
    .admin-container .left-navbar .admin-sidenav-item .admin-sidenav-item-1 {
      padding-left: 15px; }
    .admin-container .left-navbar .admin-sidenav-item .admin-sidenav-item-2 {
      padding-left: 15px; }
    .admin-container .left-navbar .admin-sidenav-item a {
      width: 100%;
      text-decoration: none;
      display: block;
      color: black;
      padding: 8px 12px 8px 12px;
      margin: 0px;
      cursor: pointer; }
    .admin-container .left-navbar .admin-sidenav-item a:hover {
      background-color: #cccccc;
      color: white; }
    .admin-container .left-navbar .admin-sidenav-item a:active {
      background-color: #e12829;
      color: white; }
  .admin-container .right-container {
    margin-left: 16.666666666666664%;
    position: fixed;
    background-color: white;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 50px !important; }

body {
  background-color: #fff; }

.selected-item {
  margin: 10px;
  padding-left: 16px; }
  .selected-item .msg-details {
    border-right: 1px solid rgba(0, 0, 0, 0.4); }
    .selected-item .msg-details .msg-headings {
      margin-bottom: 0px; }
      .selected-item .msg-details .msg-headings span {
        font-size: 14px;
        font-weight: 400; }
        .selected-item .msg-details .msg-headings span span {
          font-size: 15px;
          font-weight: 400; }
    .selected-item .msg-details div span {
      font-size: 14px; }
  .selected-item .attachement-container {
    padding-top: 20px;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .selected-item .attachement-container .attachments {
      max-height: 260px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
      overflow-y: hidden;
      overflow-x: hidden; }
      .selected-item .attachement-container .attachments .item {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid #ddd;
        font-size: 14px; }
      .selected-item .attachement-container .attachments .heading {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        z-index: 1;
        background-color: white; }
      .selected-item .attachement-container .attachments .item:hover {
        background-color: #f5f5f5; }
    .selected-item .attachement-container .attachments:hover {
      overflow-y: auto; }
    .selected-item .attachement-container .attachments::-webkit-scrollbar {
      width: 8px; }
    .selected-item .attachement-container .attachments::-webkit-scrollbar-track {
      background: #f1f1f1; }
    .selected-item .attachement-container .attachments::-webkit-scrollbar-thumb {
      background: #ffb380;
      /* #ffa366; */ }
    .selected-item .attachement-container .attachments::-webkit-scrollbar-thumb:hover {
      background: #e12829; }

.attachmentss {
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden; }
  .attachmentss .item {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px; }
  .attachmentss .item:hover {
    background-color: #f5f5f5; }

/* width */
.attachmentss::-webkit-scrollbar {
  width: 8px; }

/* Track */
.attachmentss::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
.attachmentss::-webkit-scrollbar-thumb {
  background: #ffb380; }

/* Handle on hover */
.attachmentss::-webkit-scrollbar-thumb:hover {
  background: #e12829; }

.heading {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: white; }

.heading-3 {
  font-weight: 600; }

.file-upload-wrapper {
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden; }
  .file-upload-wrapper .upload {
    background-color: #f5f5f5;
    padding: 10px 0px 0px 0px;
    padding: 0px;
    margin: 1%;
    height: 34px; }
    .file-upload-wrapper .upload .filename {
      font-weight: 600;
      color: #0062cc;
      font-size: 13px;
      margin: 6px;
      text-overflow: ellipsis;
      width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .file-upload-wrapper .upload .sp-progress-bar {
      padding-top: 10px; }
    .file-upload-wrapper .upload .btnremovefile {
      cursor: pointer;
      margin-right: 5px;
      font-size: 20px; }

.news-dashbord {
  margin: 10px;
  margin-top: 10px;
  padding: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff; }
  .news-dashbord .item-c {
    margin: auto;
    display: inline-block; }
  .news-dashbord .Title {
    align-items: center;
    white-space: nowrap; }
  .news-dashbord .item-c1 {
    margin: auto;
    display: inline-block;
    width: 210px;
    margin: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 4px;
    cursor: pointer; }
  .news-dashbord .item-title {
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    opacity: 0.6;
    margin: 0px;
    text-align: center;
    text-transform: capitalize; }
  .news-dashbord .item-count {
    font-weight: bold;
    font-size: 1.4rem;
    display: block;
    line-height: 1;
    margin: 1rem auto;
    margin-top: 1rem;
    text-align: center; }
  .news-dashbord .item-count1 {
    font-weight: bold;
    font-size: 1rem;
    display: block;
    line-height: 1;
    margin: 1rem auto;
    margin-top: 1.5rem; }

.create-news {
  margin: 10px;
  margin-top: 10px;
  padding: 10px !important;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 100px; }

.edit-img {
  height: 500px;
  overflow-y: auto; }
  .edit-img .img-div {
    height: 420px;
    width: 270px;
    float: left;
    margin: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    .edit-img .img-div .sp-img {
      height: 380px;
      width: 100%;
      max-width: 100%; }

.action-buttons {
  margin-top: 10px;
  margin-bottom: 100px; }

.sp-icon1 {
  font-size: 25px;
  border-radius: 50%;
  padding: 7px 12px;
  font-weight: 300;
  margin-right: 15px;
  margin-top: 10px;
  background-color: whitesmoke;
  cursor: pointer; }

.export-template {
  min-width: 500px; }

.time-stamp-right {
  color: #616770;
  font-size: 12px;
  line-height: 1.34;
  float: right; }

.news-td {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px; }
  .news-td h6 {
    line-height: 1.3; }
    .news-td h6 span {
      line-height: 1.3; }
  .news-td h6:nth-child(2) {
    margin-bottom: 3px; }
  .news-td div span {
    font-size: 13px; }
  .news-td .original-source {
    color: #616770;
    font-size: 12px;
    line-height: 1.34;
    margin: 5px 0px; }

.poll-otion {
  background-color: whitesmoke;
  margin: 5px 0px;
  padding-left: 10px !important;
  border-radius: 8px;
  width: 90%; }
  .poll-otion .poll-text {
    padding: 6px 0px 0px 0px;
    margin: 0px;
    overflow: hidden; }
  .poll-otion .poll-ot1 .checkb {
    color: #e12829;
    font-size: 26px;
    cursor: pointer; }
  .poll-otion .poll-ot1 .checkb:hover {
    color: #e65c00;
    cursor: pointer; }

.poll-otion:hover {
  cursor: pointer;
  background-color: #ebebeb; }

.sp-icon {
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  padding: 3px 6px 0px 5px; }

.sp-icon:hover {
  background-color: #f2f2f2; }

.sp-file {
  display: none; }

.sp-img {
  height: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 200px; }

.sp-main-video {
  max-height: 100%;
  height: 100%;
  min-height: 100%;
  border: 0px; }
  .sp-main-video div.fb-video span {
    height: 100% !important;
    width: 100% !important;
    align-items: center;
    vertical-align: middle; }
  .sp-main-video div.fb-video iframe {
    width: 100% !important;
    min-width: 100% !important;
    height: 100% !important; }
  .sp-main-video .sp-nav-btn {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    border: none;
    padding: 7px;
    padding-top: 20px;
    padding-bottom: 20px;
    position: absolute; }
  .sp-main-video .sp-btn-left {
    top: 40%;
    left: 0px; }
  .sp-main-video .sp-btn-right {
    top: 40%;
    right: 0px; }

.youtube-frame-thumb {
  width: 100%;
  border: 0px;
  min-height: 100%; }

.fb-video-player span {
  height: 100% !important;
  width: 100% !important;
  align-items: center;
  vertical-align: middle; }

.fb-video-player iframe {
  height: 100% !important;
  min-height: 100% !important;
  width: 100% !important;
  min-width: 100% !important; }

.sp-fb-iframe {
  border: none;
  overflow: hidden; }

.hover-ef {
  position: absolute;
  z-index: 1;
  width: 100%; }

.files-selection {
  width: 90% !important;
  min-width: 90% !important; }
  .files-selection .files-selection-co {
    margin-top: 10px;
    display: block;
    height: 480px;
    max-height: 480px;
    overflow-y: auto; }
    .files-selection .files-selection-co .files-select {
      width: 300px;
      height: 300px;
      float: left;
      margin: 5px;
      padding: 10px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); }
      .files-selection .files-selection-co .files-select .video-co {
        width: 100%;
        max-width: 100%;
        max-height: 200px !important;
        min-height: 200px !important;
        display: inline-block;
        overflow: hidden; }
      .files-selection .files-selection-co .files-select .image-co {
        max-height: 200px !important;
        display: inline-block;
        overflow: hidden;
        width: 100%;
        max-width: 100%; }

.loading1 {
  position: absolute;
  z-index: 100000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.1); }
  .loading1 .loading-c1 {
    position: absolute;
    top: 50%;
    left: 50%; }

.chkLable {
  font-size: 14px;
  margin: 0px;
  margin-bottom: 3px;
  font-weight: 500; }

.stream-actions {
  margin-top: 10px; }

.profile-pic {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin-right: 8px;
  padding: 0px;
  float: left; }

.column {
  margin-top: 5px;
  float: left;
  min-width: 60px;
  width: 60px; }

.column1 {
  float: left;
  width: 70%;
  padding: 10px; }

.row:after {
  content: "";
  display: table;
  clear: both; }

.video-container {
  height: 320px;
  max-height: 320px;
  width: 100%;
  max-width: 100%;
  min-height: 280px; }

.youtube-container {
  height: 280px;
  max-height: 280px;
  width: 100%;
  max-width: 100%;
  min-height: 280px; }

.image-container {
  height: auto;
  width: 100%;
  max-width: 100%; }

@media (min-width: 1200px) {
  .selectedUserInfo .ms-lg7-5 {
    width: 62%; } }

.selectedUserInfo .p-section {
  padding-top: 15px; }

.selectedUserInfo .sp-card-3 {
  margin: 0px !important; }

.selectedUserInfo .source-container {
  height: 100%; }

.selectedUserInfo .sp-player {
  padding: 0px 14px;
  padding-bottom: 10px; }
  .selectedUserInfo .sp-player div span {
    font-size: 13px; }

.comment-p {
  color: #e12829;
  margin-right: 10px;
  cursor: pointer; }
  .comment-p .arrow-icon {
    margin-top: 5px; }

.sp-views {
  margin: 10px;
  margin-top: 24px;
  color: #616770;
  font-size: 14px; }

.apply-link {
  padding: 10px;
  margin-bottom: 20px; }

.covid-19 {
  border: none;
  width: 100%;
  height: 400px; }

.sp-scroll-vr1 {
  max-height: 400px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: -webkit-sticky;
  padding: 12px; }
  .sp-scroll-vr1 .heading {
    margin-top: 6px;
    padding-left: 8px;
    font-size: 16px;
    margin-bottom: 0px;
    color: #6b717b;
    font-weight: bold; }
  .sp-scroll-vr1 .sp-headline {
    padding: 5px; }
    .sp-scroll-vr1 .sp-headline p {
      max-height: 52px;
      margin: 5px;
      width: 100%;
      overflow: hidden;
      cursor: pointer;
      text-overflow: ellipsis; }
    .sp-scroll-vr1 .sp-headline a,
    .sp-scroll-vr1 .sp-headline a:hover,
    .sp-scroll-vr1 .sp-headline a:focus {
      text-decoration: none;
      cursor: pointer;
      color: #000; }
    .sp-scroll-vr1 .sp-headline hr {
      margin: 0px; }

.sp-img-card .sp-img {
  width: 100%;
  max-width: 100%; }

.privacy {
  padding: 7px; }
  .privacy a {
    color: #616770;
    font-size: 13px;
    text-decoration: none;
    margin: 6px;
    cursor: pointer; }
  .privacy a:hover {
    text-decoration: underline;
    cursor: pointer; }
  .privacy .title-a1 {
    margin: 6px;
    color: #616770;
    font-size: 13px; }

.privacy-c {
  padding: 10px; }

.telugu {
  font-weight: bold; }
  .telugu .pre-form p {
    margin: 0px; }
  .telugu .t-link {
    text-decoration: none; }

.span-link {
  cursor: pointer; }

.span-link:hover {
  text-decoration: underline; }

.sp-img-txt-card .heading {
  margin-top: 10px;
  padding-left: 18px;
  font-size: 16px;
  margin-bottom: 6px;
  color: #6b717b;
  font-weight: bold; }

.sp-img-txt-card .sp-img {
  height: 240px;
  width: 100%;
  max-width: 100%; }

.sp-img-txt-card .read-more-li {
  text-decoration: none;
  padding: 10px;
  float: right;
  font-size: 14px;
  position: absolute;
  bottom: 5px;
  color: #e12829;
  right: 0; }

.overlay-img1 {
  cursor: pointer; }
  .overlay-img1 .sp-nav-btn {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    border: none;
    z-index: 3;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px; }
  .overlay-img1 .sp-btn-left {
    top: 40%;
    float: left;
    left: 0; }
  .overlay-img1 .sp-btn-right {
    top: 40%;
    float: right;
    right: 0; }

.my-profile {
  min-height: 94.5vh;
  padding-bottom: 20px !important; }
  .my-profile p {
    font-size: 14px; }
  .my-profile .title {
    font-weight: 500; }
  .my-profile .card {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: block; }
  .my-profile .img-container {
    margin-top: 30px;
    text-align: center; }
  .my-profile .btn-edit {
    margin-top: 4px;
    background-color: #f5f2ef;
    font-size: 13px;
    padding: 1px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .my-profile .btn-edit:hover,
  .my-profile .btn-edit:active {
    background-color: #ebe6e0; }
  .my-profile .header {
    background-color: #f5f2ef;
    padding: 8px;
    padding-left: 10px;
    font-weight: 500;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .my-profile .btn-action {
    float: right; }
  .my-profile .card-item {
    padding: 5px 10px; }
  .my-profile .name {
    width: 70%; }
  .my-profile .card-2 {
    margin-top: 12px; }
  .my-profile .hr1 {
    border-top: 1px solid rgba(0, 0, 0, 0.8); }

.avatar-3 {
  height: 200px;
  width: 200px;
  border-radius: 10% !important; }

.avatar-3 {
  height: auto;
  width: 50%;
  margin: auto;
  display: block;
  border-radius: 5% !important; }

@media (min-width: 481px) {
  .avatar-3 {
    height: auto;
    width: 100% !important;
    border-radius: 5% !important; }
  .my-profile {
    padding-top: 40px !important; } }

@media (max-width: 481px) {
  .my-profile {
    padding-top: 20px !important; } }

.reporter-tab .reporter-details-wrapper {
  padding: 10px; }

.reporter-tab .reporter-details {
  max-height: 468px;
  height: 468px;
  min-height: 468px;
  margin-top: 15px;
  border-radius: 10px;
  width: 297px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  display: inline-block;
  overflow: hidden; }
  .reporter-tab .reporter-details .r-title {
    text-align: center;
    font-size: 40px;
    background-color: #e12829;
    color: white;
    margin: 0px; }
  .reporter-tab .reporter-details .r-img-w {
    padding-top: 20px; }
    .reporter-tab .reporter-details .r-img-w .r-image {
      height: 140px;
      width: 120px;
      margin: auto;
      margin-left: 110px;
      margin-top: 98px;
      display: block;
      border-radius: 10% !important; }
  .reporter-tab .reporter-details .r-name {
    margin: 0px;
    margin-left: 110px;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: bold; }
  .reporter-tab .reporter-details .r-id {
    margin: 0px;
    margin-left: 110px;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: bold; }

.r-image1 {
  height: 140px;
  width: 120px;
  margin-left: 10px;
  display: block;
  border-radius: 10% !important; }

.my-dashboard {
  margin-top: 46px;
  background-color: white;
  height: calc(100vh - 40px);
  padding-bottom: 10px; }

.compose-co {
  padding: 10px;
  margin: 5px 0px 10px 0px;
  background-color: white;
  padding-bottom: 5px; }
  .compose-co .c-btns {
    text-align: center;
    margin-bottom: 6px; }
    .compose-co .c-btns .c-btn {
      border-radius: 10px;
      cursor: pointer;
      border: none;
      color: #6b717b;
      padding: 18px 30px;
      -webkit-transition: background-color 0.1s;
      transition: background-color 0.1s; }
    .compose-co .c-btns .c-btn:hover {
      background-color: whitesmoke; }
    .compose-co .c-btns .c-btn2 {
      margin-left: 30px; }
    .compose-co .c-btns .sp-active {
      border-bottom: 2px solid #e12829; }
  .compose-co .compose-c {
    background-color: #fff; }
    .compose-co .compose-c .sp-compose-body {
      padding: 10px;
      overflow: auto;
      overflow-y: auto;
      max-height: 400px; }
      .compose-co .compose-c .sp-compose-body .files {
        max-height: 400px;
        height: 300px;
        overflow: hidden;
        overflow-y: scroll; }
      .compose-co .compose-c .sp-compose-body .upload {
        background-color: #f5f5f5;
        padding: 10px 0px 0px 0px;
        padding: 0px;
        margin: 1%;
        height: 34px; }
        .compose-co .compose-c .sp-compose-body .upload .filename {
          font-weight: 600;
          color: #0062cc;
          font-size: 13px;
          margin: 6px;
          text-overflow: ellipsis;
          width: 95%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        .compose-co .compose-c .sp-compose-body .upload .sp-progress-bar {
          padding-top: 10px; }
        .compose-co .compose-c .sp-compose-body .upload .btnremovefile {
          cursor: pointer;
          margin-right: 5px;
          font-size: 20px; }
    .compose-co .compose-c .sp-compose-footer {
      box-sizing: border-box;
      height: 40px;
      width: 100%;
      bottom: 0px; }
      .compose-co .compose-c .sp-compose-footer .btn-send {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: -2px; }

@media (max-width: 481px) {
  .c-btns .c-btn {
    padding: 18px 10px !important; }
  .c-btns .c-btn2 {
    margin-left: 0px !important; } }

@media (min-width: 480px) and (max-width: 639.99999px) {
  .c-btns .c-btn {
    padding: 18px 10px !important; }
  .c-btns .c-btn2 {
    margin-left: 0px !important; } }

.callout-wrap {
  padding: 10px;
  max-width: 300px;
  max-height: 300px;
  overflow-y: auto; }

.sp-live-c {
  margin-top: 60px; }

.live-comments {
  border: 1px solid rgba(0, 0, 0, 0.2);
  min-height: 600px; }

.sp-comments-disabled {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  text-align: center; }

.sp-img-thumb {
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 440px; }

@media (max-width: 372px) {
  .youtube-live {
    height: 200px; } }

@media (min-width: 372px) and (max-width: 480px) {
  .youtube-live {
    height: 300px; } }

@media (min-width: 480px) and (max-width: 767.98px) {
  .youtube-live {
    height: 360px; } }

@media (min-width: 767.98px) and (max-width: 991.98px) {
  .youtube-live {
    height: 420px; } }

@media (min-width: 991.98px) {
  .youtube-live {
    height: 500px; } }

:root {
  --main-color: #1C4B79;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f0f2f5;
  min-width: 330px; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

textarea {
  overflow: auto;
  resize: vertical; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1 {
  font-size: 2.5rem; }

h2 {
  font-size: 2rem; }

h3 {
  font-size: 1.75rem; }

h4 {
  font-size: 1.5rem; }

h5 {
  font-size: 1.25rem; }

h6 {
  font-size: 1rem; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.sp-center {
  text-align: center; }

.sp-float-center {
  margin-left: 30%; }

.sp-show {
  display: block !important; }

.sp-hide {
  display: none !important; }

button:focus {
  outline: 0; }

.sp-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto; }

.sp-container1 {
  width: 100%;
  margin-right: auto;
  margin-left: auto; }

.sp-nav-container {
  width: 100%;
  display: table;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto; }
  .sp-nav-container .home-link {
    font-size: 16px; }
  .sp-nav-container .home-lin {
    margin: 0px;
    padding: 0px;
    margin-bottom: -6px; }
  .sp-nav-container .sp-logotext {
    color: white;
    margin-top: -30px;
    display: inline-block; }

@media (min-width: 481px) {
  .sp-logo {
    height: 100px; } }

@media (min-width: 576px) {
  .sp-container {
    max-width: 100%; }
  .sp-nav-container {
    max-width: 100%; }
  .sp-container1 {
    max-width: 100%; } }

@media (min-width: 768px) {
  .sp-container {
    max-width: 700px; }
  .sp-container1 {
    max-width: 700px; }
  .sp-nav-container {
    max-width: 700px; }
  .ms-lg7-5 {
    width: 63%; }
  .ms-lg4-5 {
    width: 37%; } }

@media (min-width: 992px) {
  .sp-container {
    max-width: 860px; }
  .sp-container1 {
    max-width: 960px; }
  .sp-nav-container {
    max-width: 860px; }
  .ms-lg7-5 {
    width: 63%; }
  .ms-lg4-5 {
    width: 37%; } }

@media (min-width: 1200px) {
  .sp-container {
    max-width: 860px; }
  .sp-container1 {
    max-width: 1200px; }
  .sp-nav-container {
    max-width: 860px; }
  .ms-lg7-5 {
    width: 63%; }
  .ms-lg4-5 {
    width: 37%; }
  .sticky-right1 {
    position: fixed;
    top: 148px;
    overflow-y: scroll;
    height: calc(100vh - 10px);
    margin-top: -10px;
    padding-top: 20px;
    padding-bottom: 90px;
    max-width: 310px;
    min-width: 310px; } }

@media (min-width: 1300px) {
  .sp-container1 {
    max-width: 1300px; } }

.test {
  font-weight: bold; }

.sp-clearFix {
  clear: both; }

.sp-danger {
  color: #990000;
  font-size: 12px; }

.sp-danger2 {
  color: #990000;
  font-size: 14px; }

.sp-danger3 {
  color: #990000; }

.sp-pointer {
  cursor: pointer !important; }

.sp-bg-white {
  background-color: #fff !important; }

.sp-white {
  color: #fff; }

.sp-mt5 {
  margin-top: 5px !important; }

.sp-no-pm {
  padding: 0px;
  margin: 0px; }

.profile-name {
  color: #385898;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  line-height: 1.3;
  margin-top: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px;
  margin-top: 5px; }

.sp-m10 {
  margin: 10px !important; }

.sp-p5 {
  padding: 5px !important; }

.sp-p10 {
  padding: 10px !important; }

.sp-pt10 {
  padding-top: 10px !important; }

.sp-pt5 {
  padding-top: 5px !important; }

.sp-pl10 {
  padding-left: 10px !important; }

.sp-pt15 {
  padding-top: 15px !important; }

.sp-m5 {
  margin-top: 5px !important; }

.sp-m3 {
  margin: 3px; }

.sp-mr10 {
  margin-right: 10px !important; }

.sp-mr20 {
  margin-right: 20px !important; }

.sp-f12 {
  font-size: 12px; }

.sp-n {
  line-height: 1.34;
  color: #616770; }

.sp-f13 {
  font-size: 13px; }

.sp-f14 {
  font-size: 13px; }

.timestamp-fixed {
  position: absolute;
  bottom: 0px;
  right: 10px; }

.sp-timestamp {
  color: #616770;
  font-size: 13px; }

.avatar {
  height: 200px;
  width: 100%;
  max-width: 200px;
  border-radius: 90% !important; }

.sp-mt10 {
  margin-top: 10px !important; }

.sp-mt50 {
  margin-top: 50px; }

.sp-mt30 {
  margin-top: 30px !important; }

.sp-mt40 {
  margin-top: 40px; }

.time-stamp {
  color: #616770;
  font-size: 12px;
  line-height: 1.34; }

.time-stam2 {
  color: #616770;
  font-size: 14px;
  line-height: 1.34; }

.sp-hr {
  margin: 5px; }

.sp-float-left {
  float: left !important; }

.sp-float-right {
  float: right !important; }

.show-more-link {
  cursor: pointer;
  text-decoration: none; }

.show-more-link:hover {
  text-decoration: none; }

.profile-info-2 .avatar-2 {
  height: auto;
  width: 50%;
  display: block;
  border-radius: 5% !important; }

.capitalize {
  text-transform: capitalize; }

table.sp-table-list {
  border-collapse: collapse;
  width: 100%; }
  table.sp-table-list th,
  table.sp-table-list td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px; }
  table.sp-table-list tr:hover {
    background-color: #f5f5f5; }

.sp-btn-m {
  color: white;
  border: none; }

.sp-btn-m:hover {
  color: white; }

.sp-btn-m:focus {
  color: white;
  border: 0px;
  outline: 0; }

.sp-btn-success {
  background-color: #009900; }

.sp-btn-success:hover {
  background-color: #008000; }

.sp-btn-success:focus {
  background-color: #008000; }

.sp-btn-black {
  background-color: #fff;
  border: 2px solid black;
  color: black;
  font-weight: 500;
  font-size: 13px; }

.sp-btn-black:hover {
  background-color: black;
  color: white; }

.sp-btn-black:focus {
  background-color: black;
  color: white; }

.sp-btn-process {
  background-color: #ffd11a; }

.sp-btn-process:hover {
  background-color: #ffcc00; }

.sp-btn-process:focus {
  background-color: #ffcc00; }

.sp-btn-danger {
  background-color: #ff1a1a; }

.sp-btn-danger:hover {
  background-color: #E12829; }

.sp-btn-danger:focus {
  background-color: #E12829; }

.sp-ml10 {
  margin-left: 10px !important; }

.sp-ml5 {
  margin-left: 5px !important; }

.sp-remove {
  margin: 0px;
  font-size: 23px;
  margin-top: -8px; }

.approve-form {
  padding-left: 26px !important;
  padding-bottom: 100px; }
  .approve-form .btn-remove-file {
    margin: 0px;
    font-size: 30px;
    cursor: pointer;
    line-height: 1rem;
    padding: 4px 6px 9px 6px;
    margin-top: 2px; }
  .approve-form .add-icon {
    margin: 0px;
    font-size: 20px;
    cursor: pointer; }
  .approve-form .dropdown-fileType {
    width: 160px; }

.sp-mb10 {
  margin-bottom: 10px; }

.sp-mb20 {
  margin-bottom: 20px; }

.sp-mb30 {
  margin-bottom: 30px; }

.sp-mb0 {
  margin-bottom: 0px !important; }

.title12 {
  height: 20px;
  margin-bottom: 0px; }

.sp-pb10 {
  padding-bottom: 10px; }

.sp-pb20 {
  padding-bottom: 20px; }

.btn-remove-file {
  margin: 0px;
  font-size: 26px;
  cursor: pointer;
  line-height: 1rem;
  padding: 4px 6px 9px 6px;
  margin-top: 2px; }

.add-icon {
  margin: 0px;
  font-size: 20px;
  cursor: pointer;
  padding: 2px 5px 1px 5px;
  border-radius: 5px; }

.add-icon:hover {
  background-color: #f2f2f2; }

.dropdown-fileType {
  width: 160px; }

.sp-main-btn {
  color: white;
  background-color: #e12829;
  border: none; }

.sp-main-btn:hover {
  background-color: #ca1c1c;
  color: white;
  border: none; }

.sp-main-btn:focus {
  background-color: #ca1c1c;
  color: white;
  border: 0px;
  outline: 0;
  border: none; }

.avatar-3 {
  height: auto;
  max-width: 160px;
  height: 160px;
  display: block;
  border-radius: 5% !important; }

.user-profile3 {
  margin-top: 14px;
  margin-left: 10px; }

pre {
  font-size: 1em !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.txt-filpath {
  width: 500px; }

.txt-filname {
  width: 300px; }

.sp-sm20 {
  width: 20%; }

.material-table {
  margin-top: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .material-table .pagination {
    float: right !important; }
    .material-table .pagination span {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      font-size: 16px;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
    .material-table .pagination .nav-btn {
      cursor: pointer; }
    .material-table .pagination span.nav-btn:hover {
      background-color: rgba(0, 0, 0, 0.2); }
  .material-table .pagination a.active {
    background-color: dodgerblue;
    color: white; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box; }

progress {
  vertical-align: baseline; }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ca1c1c;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none; } }

.main-contai {
  margin: 15px 0px;
  background-color: white;
  padding-bottom: 5px; }

.sp-green {
  background-color: #3ac47d !important; }

.sp-yellow {
  background-color: #f7b924 !important; }

.sp-red {
  background-color: #d92550 !important; }

.sp-blue {
  background-color: #3f6ad8 !important; }

.sp-orange {
  background-color: #e12829 !important; }

.sp-black {
  background-color: black !important; }

.view-all-button {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 8px;
  background-color: #e12829;
  padding: 18px 30px 18px 30px; }

.view-all-button:hover {
  background-color: #ca1c1c; }

.admin-sidenav-item a.active {
  background-color: #e12829 !important;
  color: white !important; }

.shimmer-main-w {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px; }

.sp-34 {
  margin-left: -15px; }

.sp-mb100 {
  margin-bottom: 100px; }

.analaysis {
  padding: 10px; }
  .analaysis .an-heading {
    font-weight: 500;
    margin-bottom: 0px; }
  .analaysis .an-text {
    padding: 0px 10px;
    font-size: 13px;
    color: #4d4d4d; }

.news-info1 {
  margin-top: -5px; }

.sp-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 86px; }

.main-container-wrapper {
  padding-top: 5px;
  padding-bottom: 5px; }

.c-style1 {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px; }

@media (max-width: 1200px) {
  .sticky-right1 {
    margin-top: 10px; } }

/* width */
.sp-scroll-hr::-webkit-scrollbar {
  height: 5px; }

/* Track */
.sp-scroll-hr::-webkit-scrollbar-track {
  background: white; }

/* Handle */
.sp-scroll-hr::-webkit-scrollbar-thumb {
  background: #d9d9d9; }

/* Handle on hover */
.sp-scroll-hr::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3; }

.sp-scroll-vr {
  scroll-behavior: smooth; }

.sp-scroll-vr:hover {
  overflow-y: scroll; }

.sp-scroll-vr::-webkit-scrollbar {
  width: 8px; }

.sp-scroll-vr::-webkit-scrollbar-thumb {
  background: #d9d9d9; }

.sp-scroll-vr::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3; }

.cs {
  overflow-y: hidden; }

.cs:hover {
  overflow-y: auto; }

/* width */
.cs::-webkit-scrollbar {
  width: 8px; }

/* Track */
.cs::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
.cs::-webkit-scrollbar-thumb {
  background: #e33535; }

/* Handle on hover */
.cs::-webkit-scrollbar-thumb:hover {
  background: #e12829; }

/* width */
.cs textarea::-webkit-scrollbar {
  width: 10px; }

/* Track */
.cs textarea::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
.cs textarea::-webkit-scrollbar-thumb {
  background: #ffb380; }

/* Handle on hover */
.cs textarea.textarea::-webkit-scrollbar-thumb:hover {
  background: #e12829; }

*:focus {
  outline: none; }

.sp-txt-center {
  text-align: center; }

/* width */
.sp-scroll-vr1::-webkit-scrollbar {
  width: 8px;
  border-bottom-right-radius: 20px !important;
  opacity: 0; }

/* Track */
.sp-scroll-vr1::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
.sp-scroll-vr1::-webkit-scrollbar-thumb {
  background: #e12829; }

/* Handle on hover */
.sp-scroll-vr1::-webkit-scrollbar-thumb:hover {
  background: #ca1c1c;
  opacity: 1; }

.overlay-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer; }
  .overlay-img .sp-nav-btn {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    border: none;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px; }
  .overlay-img .sp-btn-left {
    position: relative;
    top: 40%;
    float: left; }
  .overlay-img .sp-btn-right {
    position: relative;
    top: 40%;
    float: right; }

.sp-card-m {
  max-height: 450px;
  height: 450px;
  min-height: 450px;
  margin-top: 15px;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  display: inline-block; }
  .sp-card-m .sp-card-img {
    display: inline-block;
    height: 100%;
    width: 100%;
    border: none;
    overflow: hidden;
    background-color: white;
    border-radius: 10px; }

.sp-mt100 {
  margin-top: 325px; }

table.sp-table-u {
  border: 1px solid black;
  border-collapse: collapse;
  border-spacing: 5px;
  width: 100%;
  background-color: #f1f1c1; }
  table.sp-table-u th {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 10px;
    text-align: left; }
  table.sp-table-u td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 6px; }
  table.sp-table-u tr:nth-child(even) {
    background-color: #eee; }
  table.sp-table-u tr:nth-child(odd) {
    background-color: #fff; }

.reporter-details {
  max-height: 468px;
  height: 468px;
  min-height: 468px;
  margin-top: 15px;
  border-radius: 10px;
  width: 297px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  display: inline-block;
  overflow: hidden; }
  .reporter-details .r-title {
    text-align: center;
    font-size: 40px;
    background-color: #e12829;
    color: white;
    margin: 0px; }
  .reporter-details .r-img-w {
    padding-top: 20px; }
    .reporter-details .r-img-w .r-image {
      height: 140px;
      width: 120px;
      margin: auto;
      margin-left: 110px;
      margin-top: 98px;
      display: block;
      border-radius: 10% !important; }
  .reporter-details .r-name {
    margin: 0px;
    margin-left: 110px;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: bold; }
  .reporter-details .r-id {
    margin: 0px;
    margin-left: 110px;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: bold; }

.sp-bold {
  font-weight: bold; }

.blinking-button {
  margin: 5px 0px 0px 5px;
  border: none;
  color: #737373;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  padding: 4px 20px !important;
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite; }

@-webkit-keyframes glowing {
  0% {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 3px #ffffff;
    color: #737373; }
  50% {
    background-color: #E12829;
    -webkit-box-shadow: 0 0 10px #E12829; }
  100% {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 3px #ffffff;
    color: #737373; } }

@keyframes glowing {
  0% {
    background-color: #ffffff;
    box-shadow: 0 0 3px #ffffff; }
  50% {
    background-color: #E12829;
    box-shadow: 0 0 10px #E12829; }
  100% {
    background-color: #ffffff;
    box-shadow: 0 0 3px #ffffff; } }

.blinking {
  font-size: 18px;
  -webkit-animation: blinkingText 1.2s infinite;
          animation: blinkingText 1.2s infinite; }

@-webkit-keyframes blinkingText {
  0% {
    color: #000; }
  49% {
    color: #000; }
  60% {
    color: transparent; }
  99% {
    color: transparent; }
  100% {
    color: #000; } }

@keyframes blinkingText {
  0% {
    color: #000; }
  49% {
    color: #000; }
  60% {
    color: transparent; }
  99% {
    color: transparent; }
  100% {
    color: #000; } }

.sp-center1 {
  margin: auto;
  width: 30%; }

.sp-center2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%; }

.sp-dropdown {
  width: 200px; }

.sp-br5 {
  border-radius: 5px; }

.sp-1 {
  width: 9.666666666666664%; }

.sp-9 {
  width: 86.33333333333334%; }

@media (max-width: 372px) {
  .sp-1 {
    width: 15.666666666666664%; }
  .sp-9 {
    width: 80.33333333333334%; } }

@media (min-width: 372px) and (max-width: 480px) {
  .sp-1 {
    width: 13.666666666666664%; }
  .sp-9 {
    width: 80.33333333333334%; } }

@media (min-width: 480px) and (max-width: 767.98px) {
  .sp-1 {
    width: 14.666666666666664%; }
  .sp-9 {
    width: 77.33333333333334%; } }

@media (min-width: 767.98px) and (max-width: 991.98px) {
  .sp-1 {
    width: 12.666666666666664%; }
  .sp-9 {
    width: 80.33333333333334%; } }

.comments-container .login-warning-c {
  display: flow-root; }
  .comments-container .login-warning-c .login-warning {
    margin-right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    float: right;
    padding: 4px;
    margin-top: 8px; }

.comments-container .button-div {
  min-height: 20px; }

.comments-container .comment-info {
  padding: 0px !important;
  margin-bottom: 8px; }
  .comments-container .comment-info .ms-Grid-row {
    margin: 0px; }
  .comments-container .comment-info .comment-details {
    background-color: whitesmoke;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-bottom: 10px; }
    .comments-container .comment-info .comment-details .profile-name2 {
      color: #385898;
      cursor: pointer;
      text-decoration: none;
      font-weight: 600;
      line-height: 0.8;
      margin-top: 10px;
      overflow: hidden;
      font-size: 14px;
      text-overflow: ellipsis; }
    .comments-container .comment-info .comment-details .comment {
      margin: 0px;
      font-size: 14px;
      line-height: 1.34;
      overflow: hidden; }
  .comments-container .comment-info .profile-pic2-c {
    padding-right: 0px; }
    .comments-container .comment-info .profile-pic2-c .profile-pic2 {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      margin-right: 8px;
      padding: 0px;
      float: left; }

.comments-container .comment-info4 {
  padding: 0px !important; }
  .comments-container .comment-info4 .ms-Grid-row {
    margin: 0px; }
  .comments-container .comment-info4 .comment-details {
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-bottom: 7px; }
    .comments-container .comment-info4 .comment-details .profile-name2 {
      color: #737373;
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
      line-height: 0.8;
      margin-top: 10px;
      overflow: hidden;
      font-size: 14px;
      text-overflow: ellipsis; }
    .comments-container .comment-info4 .comment-details .comment {
      margin: 0px;
      font-size: 14px;
      line-height: 1.34;
      overflow: hidden; }
  .comments-container .comment-info4 .profile-pic2-c {
    padding-right: 0px; }
    .comments-container .comment-info4 .profile-pic2-c .profile-pic2 {
      height: 26px;
      width: 26px;
      border-radius: 50%;
      margin-right: 8px;
      padding: 0px;
      float: left; }

.comments-container .comment-info2 {
  padding: 0px !important;
  margin-bottom: 10px; }
  .comments-container .comment-info2 .ms-Grid-row {
    margin: 0px; }
  .comments-container .comment-info2 .comment-details {
    background-color: whitesmoke;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-bottom: 10px; }
    .comments-container .comment-info2 .comment-details .comment-in {
      outline: none;
      border: none;
      height: 100%;
      width: 100%;
      padding: 2px 4px;
      margin-top: 10px;
      background-color: whitesmoke; }
    .comments-container .comment-info2 .comment-details .comment {
      margin: 0px;
      font-size: 14px;
      line-height: 1.34; }
  .comments-container .comment-info2 .profile-pic2-c {
    padding-right: 0px; }
    .comments-container .comment-info2 .profile-pic2-c .profile-pic2 {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      margin-right: 8px;
      padding: 0px;
      float: left; }

.comments-container .comment-info3 {
  padding: 10px 0px 0px 0px;
  background-color: whitesmoke;
  margin-bottom: 10px;
  position: absolute;
  display: inline-block;
  bottom: 0px;
  width: 94%; }
  .comments-container .comment-info3 .ms-Grid-row {
    margin: 0px; }
  .comments-container .comment-info3 .comment-details {
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-bottom: 10px; }
    .comments-container .comment-info3 .comment-details .comment-in {
      outline: none;
      border: none;
      height: 100%;
      width: 100%;
      padding: 10px 4px 2px 4px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.3);
      background-color: whitesmoke; }
    .comments-container .comment-info3 .comment-details .comment {
      margin: 0px;
      font-size: 14px;
      line-height: 1.34; }
  .comments-container .comment-info3 .profile-pic2-c {
    padding-right: 0px; }
    .comments-container .comment-info3 .profile-pic2-c .profile-pic2 {
      height: 26px;
      width: 26px;
      border-radius: 50%;
      margin-right: 8px;
      padding: 0px;
      float: left; }

.comments-container .all-comments {
  max-height: 450px;
  overflow-y: auto; }

.comments-container .all-comments1 {
  max-height: 450px;
  min-height: 450px;
  overflow-y: auto; }

.comments-container .view-more {
  margin: 0px;
  margin-left: 14px;
  color: #616770;
  font-size: 13px;
  cursor: pointer;
  font-weight: 500; }

.comments-container .login-warning-c {
  display: flow-root; }
  .comments-container .login-warning-c .login-warning {
    margin-right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    float: right;
    padding: 4px; }

.live-link {
  padding: 1px 0px 0px 0px !important;
  margin-left: 5px; }

.live-gif {
  height: 38px;
  width: 100px; }

.live-a {
  margin-top: 7px !important; }

.live-s {
  position: relative;
  padding: 10px 5px;
  font-weight: bold;
  color: white;
  -webkit-animation: mymove 1s infinite;
          animation: mymove 1s infinite; }

@-webkit-keyframes mymove {
  from {
    color: white; }
  to {
    color: #E12829; } }

@keyframes mymove {
  from {
    color: white; }
  to {
    color: #E12829; } }

.sp-video-player {
  position: relative;
  width: 100%; }
  .sp-video-player .sp-quality-o {
    font-size: 12px; }
  .sp-video-player .sp-controls {
    position: absolute;
    z-index: 10;
    bottom: 0px;
    right: 0px; }

.sp-label {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500; }

.sp-img34 {
  width: 100%;
  max-width: 100%; }

