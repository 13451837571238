// #e12829;
// #ca1c1c;

@import "~office-ui-fabric-react/dist/css/fabric.min.css";

:root {
  --main-color: #1C4B79;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f0f2f5;
  min-width: 330px;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

textarea {
  overflow: auto;
  resize: vertical;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.sp-center {
  text-align: center;
}

.sp-float-center {
  margin-left: 30%;
}

// body:hover {
//   overflow-y: scroll;
// }
.sp-show {
  display: block !important;
}

.sp-hide {
  display: none !important;
}

button:focus {
  outline: 0;
}

.sp-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}


.sp-container1 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.sp-nav-container {
  width: 100%;
  display: table;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;

  .home-link {
    font-size: 16px;
  }

  .home-lin {
    margin: 0px;
    padding: 0px;
    margin-bottom: -6px;
  }

  .sp-logotext {
    color: white;
    margin-top: -30px;
    display: inline-block;
  }
}

@media (min-width: 481px) {
  .sp-logo {
    // height: 45px;
    // width: 58px;

    height: 100px;
  }
}

@media (min-width: 576px) {
  .sp-container {
    max-width: 100%;
  }

  .sp-nav-container {
    max-width: 100%;
  }

  .sp-container1 {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .sp-container {
    max-width: 700px;
  }

  .sp-container1 {
    max-width: 700px;
  }

  .sp-nav-container {
    max-width: 700px;
  }

  .ms-lg7-5 {
    width: 63%;
  }

  .ms-lg4-5 {
    width: 37%;
  }
}

@media (min-width: 992px) {
  .sp-container {
    max-width: 860px;
  }

  .sp-container1 {
    max-width: 960px;
  }

  .sp-nav-container {
    max-width: 860px;
  }

  .ms-lg7-5 {
    width: 63%;
  }

  .ms-lg4-5 {
    width: 37%;
  }
}

@media (min-width: 1200px) {
  .sp-container {
    max-width: 860px;
  }

  .sp-container1 {
    max-width: 1200px;
  }

  .sp-nav-container {
    max-width: 860px;
  }

  .ms-lg7-5 {
    width: 63%;
  }

  .ms-lg4-5 {
    width: 37%;
  }

  .sticky-right1 {
    position: fixed;
    top: 148px;
    overflow-y: scroll;
    height: calc(100vh - 10px);
    margin-top: -10px;
    padding-top: 20px;
    padding-bottom: 90px;
    max-width: 310px;
    min-width: 310px;
  }
}

@media (min-width: 1300px) {
  .sp-container1 {
    max-width: 1300px;
  }
}



.test {
  font-weight: bold;
}

.sp-clearFix {
  clear: both;
}

.sp-danger {
  color: #990000;
  font-size: 12px;
}


.sp-danger2 {
  color: #990000;
  font-size: 14px;
}

.sp-danger3 {
  color: #990000;
}

.sp-pointer {
  cursor: pointer !important;
}

.sp-bg-white {
  background-color: #fff !important;
}

.sp-white {
  color: #fff;
}

.sp-mt5 {
  margin-top: 5px !important;
}

.sp-no-pm {
  padding: 0px;
  margin: 0px;
}

.profile-name {
  color: #385898;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  line-height: 1.3;
  margin-top: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px;
  margin-top: 5px;
}

.sp-m10 {
  margin: 10px !important;
}

.sp-p5 {
  padding: 5px !important;
}

.sp-p10 {
  padding: 10px !important;
}

.sp-pt10 {
  padding-top: 10px !important;
}

.sp-pt5 {
  padding-top: 5px !important;
}


.sp-pl10 {
  padding-left: 10px !important;
}



.sp-pt15 {
  padding-top: 15px !important;
}

.sp-m5 {
  margin-top: 5px !important;
}

.sp-m3 {
  margin: 3px;
}

.sp-mr10 {
  margin-right: 10px !important;
}

.sp-mr20 {
  margin-right: 20px !important;
}

.sp-f12 {
  font-size: 12px;
}

.sp-n {
  line-height: 1.34;
  color: #616770;
}

.sp-f13 {
  font-size: 13px;
}

.sp-f14 {
  font-size: 13px;
}

.timestamp-fixed {
  position: absolute;
  bottom: 0px;
  right: 10px;
}

.sp-timestamp {
  color: #616770;
  font-size: 13px;
}

.avatar {
  height: 200px;
  width: 100%;
  max-width: 200px;
  border-radius: 90% !important;
}

.sp-mt10 {
  margin-top: 10px !important;
}

.sp-mt50 {
  margin-top: 50px;
}

.sp-mt30 {
  margin-top: 30px !important;
}

.sp-mt40 {
  margin-top: 40px;
}

.time-stamp {
  color: #616770;
  font-size: 12px;
  line-height: 1.34;
}

.time-stam2 {
  color: #616770;
  font-size: 14px;
  line-height: 1.34;
}



.sp-hr {
  margin: 5px;
}

.sp-float-left {
  float: left !important;
}

.sp-float-right {
  float: right !important;
}

.show-more-link {
  cursor: pointer;
  text-decoration: none;
}

.show-more-link:hover {
  text-decoration: none;
}

.profile-info-2 {
  .avatar-2 {
    height: auto;
    width: 50%;
    display: block;
    border-radius: 5% !important;
  }
}

.capitalize {
  text-transform: capitalize;
}

table.sp-table-list {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }

  tr:hover {
    background-color: #f5f5f5;
  }
}

.sp-btn-m {
  color: white;
  border: none;
}

.sp-btn-m:hover {
  color: white;
}

.sp-btn-m:focus {
  color: white;
  border: 0px;
  outline: 0;
}

.sp-btn-success {
  background-color: #009900;
}

.sp-btn-success:hover {
  background-color: #008000;
}

.sp-btn-success:focus {
  background-color: #008000;
}

.sp-btn-black {
  background-color: #fff;
  border: 2px solid black;
  color: black;
  font-weight: 500;
  font-size: 13px;
}

.sp-btn-black:hover {
  background-color: black;
  color: white;
}

.sp-btn-black:focus {
  background-color: black;
  color: white;
}

.sp-btn-process {
  background-color: #ffd11a;
}

.sp-btn-process:hover {
  background-color: #ffcc00;
}

.sp-btn-process:focus {
  background-color: #ffcc00;
}

.sp-btn-danger {
  background-color: #ff1a1a;
}

.sp-btn-danger:hover {
  background-color: #E12829;
}

.sp-btn-danger:focus {
  background-color: #E12829;
}

.sp-ml10 {
  margin-left: 10px !important;
}

.sp-ml5 {
  margin-left: 5px !important;
}

.sp-remove {
  margin: 0px;
  font-size: 23px;
  margin-top: -8px;
}

.approve-form {
  padding-left: 26px !important;
  padding-bottom: 100px;

  .btn-remove-file {
    margin: 0px;
    font-size: 30px;
    cursor: pointer;
    line-height: 1rem;
    padding: 4px 6px 9px 6px;
    margin-top: 2px;
  }

  .add-icon {
    margin: 0px;
    font-size: 20px;
    cursor: pointer;
  }

  .dropdown-fileType {
    width: 160px;
  }
}

.sp-mb10 {
  margin-bottom: 10px;
}

.sp-mb20 {
  margin-bottom: 20px;
}

.sp-mb30 {
  margin-bottom: 30px;
}

.sp-mb0 {
  margin-bottom: 0px !important;
}

.title12 {
  height: 20px;
  margin-bottom: 0px;
}

.sp-pb10 {
  padding-bottom: 10px;
}

.sp-pb20 {
  padding-bottom: 20px;
}

.btn-remove-file {
  margin: 0px;
  font-size: 26px;
  cursor: pointer;
  line-height: 1rem;
  padding: 4px 6px 9px 6px;
  margin-top: 2px;
}

.add-icon {
  margin: 0px;
  font-size: 20px;
  cursor: pointer;
  padding: 2px 5px 1px 5px;
  border-radius: 5px;
}

.add-icon:hover {
  background-color: #f2f2f2;
}

.dropdown-fileType {
  width: 160px;
}

.sp-main-btn {
  color: white;
  background-color: #e12829;
  border: none;
}

.sp-main-btn:hover {
  background-color: #ca1c1c;
  color: white;
  border: none;
}

.sp-main-btn:focus {
  background-color: #ca1c1c;
  color: white;
  border: 0px;
  outline: 0;
  border: none;
}

.avatar-3 {
  height: auto;
  max-width: 160px;
  height: 160px;
  display: block;
  border-radius: 5% !important;
}

.user-profile3 {
  margin-top: 14px;
  margin-left: 10px;
}

pre {
  font-size: 1em !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.txt-filpath {
  width: 500px;
}

.txt-filname {
  width: 300px;
}

.sp-sm20 {
  width: 20%;
}

.material-table {
  margin-top: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .pagination {
    float: right !important;

    span {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      font-size: 16px;
      transition: background-color 0.3s;
    }

    .nav-btn {
      cursor: pointer;
    }

    span.nav-btn:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .pagination a.active {
    background-color: dodgerblue;
    color: white;
  }
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}

progress {
  vertical-align: baseline;
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ca1c1c;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.main-contai {
  margin: 15px 0px;
  background-color: white;
  padding-bottom: 5px;
}

.sp-green {
  background-color: #3ac47d !important;
}

.sp-yellow {
  background-color: #f7b924 !important;
}

.sp-red {
  background-color: #d92550 !important;
}

.sp-blue {
  background-color: #3f6ad8 !important;
}

.sp-orange {
  background-color: #e12829 !important;
}

.sp-black {
  background-color: black !important;
}

.view-all-button {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 8px;
  background-color: #e12829;
  padding: 18px 30px 18px 30px;
}

.view-all-button:hover {
  background-color: #ca1c1c;
}

.admin-sidenav-item {
  a.active {
    background-color: #e12829 !important;
    color: white !important;
  }
}

.shimmer-main-w {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
}

.sp-34 {
  margin-left: -15px;
}

.sp-mb100 {
  margin-bottom: 100px;
}

.analaysis {
  padding: 10px;

  .an-heading {
    font-weight: 500;
    margin-bottom: 0px;
  }

  .an-text {
    padding: 0px 10px;
    font-size: 13px;
    color: #4d4d4d;
  }
}

.news-info1 {
  margin-top: -5px;
}

.sp-sticky {
  position: sticky;
  top: 86px;
}

//@media (min-width: 481px) {
.main-container-wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
}

.c-style1 {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

//}

@media (max-width: 1200px) {
  .sticky-right1 {
    margin-top: 10px;
  }
}

/* width */
.sp-scroll-hr::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.sp-scroll-hr::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.sp-scroll-hr::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}

/* Handle on hover */
.sp-scroll-hr::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
}

.sp-scroll-vr {
  scroll-behavior: smooth;
}

.sp-scroll-vr:hover {
  overflow-y: scroll;
}

.sp-scroll-vr::-webkit-scrollbar {
  width: 8px;
}

.sp-scroll-vr::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}

.sp-scroll-vr::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
}

.cs {
  overflow-y: hidden;
}

.cs:hover {
  overflow-y: auto;
}

/* width */
.cs::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.cs::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.cs::-webkit-scrollbar-thumb {
  background: #e33535;
}

/* Handle on hover */
.cs::-webkit-scrollbar-thumb:hover {
  background: #e12829;
}

/* width */
.cs textarea::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.cs textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.cs textarea::-webkit-scrollbar-thumb {
  background: #ffb380;
}

/* Handle on hover */
.cs textarea.textarea::-webkit-scrollbar-thumb:hover {
  background: #e12829;
}

*:focus {
  outline: none;
}

.sp-txt-center {
  text-align: center;
}

/* width */
.sp-scroll-vr1::-webkit-scrollbar {
  width: 8px;
  border-bottom-right-radius: 20px !important;
  opacity: 0;
}

/* Track */
.sp-scroll-vr1::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sp-scroll-vr1::-webkit-scrollbar-thumb {
  background: #e12829;
}

/* Handle on hover */
.sp-scroll-vr1::-webkit-scrollbar-thumb:hover {
  background: #ca1c1c;
  //visibility: visible;
  opacity: 1;
}

.overlay-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;

  .sp-nav-btn {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    border: none;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .sp-btn-left {
    position: relative;
    top: 40%;
    float: left;
  }

  .sp-btn-right {
    position: relative;
    top: 40%;
    float: right;
  }
}

.sp-card-m {
  max-height: 450px;
  height: 450px;
  min-height: 450px;
  margin-top: 15px;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  display: inline-block;

  .sp-card-img {
    display: inline-block;
    height: 100%;
    width: 100%;
    border: none;
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
  }
}

.sp-mt100 {
  margin-top: 325px;
}

table.sp-table-u {
  border: 1px solid black;
  border-collapse: collapse;
  border-spacing: 5px;
  width: 100%;
  background-color: #f1f1c1;

  th {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 10px;
    text-align: left;
  }

  td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 6px;
  }

  tr:nth-child(even) {
    background-color: #eee;
  }

  tr:nth-child(odd) {
    background-color: #fff;
  }
}

.reporter-details {
  max-height: 468px;
  height: 468px;
  min-height: 468px;
  margin-top: 15px;
  border-radius: 10px;
  width: 297px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  display: inline-block;
  overflow: hidden;

  .r-title {
    text-align: center;
    font-size: 40px;
    background-color: #e12829;
    color: white;
    margin: 0px;
  }

  .r-img-w {
    padding-top: 20px;

    .r-image {
      height: 140px;
      width: 120px;
      margin: auto;
      margin-left: 110px;
      margin-top: 98px;
      display: block;
      border-radius: 10% !important;
    }
  }

  .r-name {
    margin: 0px;
    margin-left: 110px;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: bold;
  }

  .r-id {
    margin: 0px;
    margin-left: 110px;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: bold;
  }
}

.sp-bold {
  font-weight: bold;
}

// .blinking-button {

// }

.blinking-button {
  // background-color: #004a7f;
  margin: 5px 0px 0px 5px;
  //-webkit-border-radius: 10px;
  //border-radius: 10px;
  border: none;
  color: #737373;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  padding: 4px 20px !important;
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 3px #ffffff;
    color: #737373;
  }

  50% {
    background-color: #E12829;
    -webkit-box-shadow: 0 0 10px #E12829;

  }

  100% {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 3px #ffffff;
    color: #737373;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #ffffff;
    -moz-box-shadow: 0 0 3px #ffffff;
  }

  50% {
    background-color: #E12829;
    -moz-box-shadow: 0 0 10px #E12829;
  }

  100% {
    background-color: #ffffff;
    -moz-box-shadow: 0 0 3px #ffffff;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #ffffff;
    box-shadow: 0 0 3px #ffffff;
  }

  50% {
    background-color: #E12829;
    box-shadow: 0 0 10px #E12829;
  }

  100% {
    background-color: #ffffff;
    box-shadow: 0 0 3px #ffffff;
  }
}

@keyframes glowing {
  0% {
    background-color: #ffffff;
    box-shadow: 0 0 3px #ffffff;
  }

  50% {
    background-color: #E12829;
    box-shadow: 0 0 10px #E12829;
  }

  100% {
    background-color: #ffffff;
    box-shadow: 0 0 3px #ffffff;
  }
}

.blinking {
  font-size: 18px;
  animation: blinkingText 1.2s infinite;
}

@keyframes blinkingText {
  0% {
    color: #000;
  }

  49% {
    color: #000;
  }

  60% {
    color: transparent;
  }

  99% {
    color: transparent;
  }

  100% {
    color: #000;
  }
}

.sp-center1 {
  margin: auto;
  width: 30%;
}

.sp-center2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.sp-dropdown {
  width: 200px;
}

.sp-br5 {
  border-radius: 5px;
}



.sp-1 {
  width: 9.666666666666664%;
}

.sp-9 {
  width: 86.33333333333334%;
}

@media (max-width: 372px) {
  .sp-1 {
    width: 15.666666666666664%;
  }

  .sp-9 {
    width: 80.33333333333334%;
  }
}

@media (min-width: 372px) and (max-width: 480px) {
  .sp-1 {
    width: 13.666666666666664%;
  }

  .sp-9 {
    width: 80.33333333333334%;
  }
}

@media (min-width: 480px) and (max-width: 767.98px) {
  .sp-1 {
    width: 14.666666666666664%;
  }

  .sp-9 {
    width: 77.33333333333334%;
  }
}

@media (min-width: 767.98px) and (max-width: 991.98px) {
  .sp-1 {
    width: 12.666666666666664%;
  }

  .sp-9 {
    width: 80.33333333333334%;
  }
}



.comments-container {
  .login-warning-c {
    display: flow-root;

    .login-warning {
      margin-right: 10px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      float: right;
      padding: 4px;
      margin-top: 8px;
    }
  }

  .button-div {
    min-height: 20px;
  }

  .comment-info {
    padding: 0px !important;
    margin-bottom: 8px;

    .ms-Grid-row {
      margin: 0px;
    }

    .comment-details {
      background-color: rgb(245, 245, 245);
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      padding-bottom: 10px;

      .profile-name2 {
        color: #385898;
        cursor: pointer;
        text-decoration: none;
        font-weight: 600;
        line-height: 0.8;
        margin-top: 10px;
        overflow: hidden;
        font-size: 14px;
        text-overflow: ellipsis;
      }

      .comment {
        margin: 0px;
        font-size: 14px;
        line-height: 1.34;
        overflow: hidden;
      }
    }

    .profile-pic2-c {
      padding-right: 0px;

      .profile-pic2 {
        height: 36px;
        width: 36px;
        border-radius: 50%;
        margin-right: 8px;
        padding: 0px;
        float: left;
      }
    }
  }


  .comment-info4 {
    padding: 0px !important;
    //margin-bottom: 8px;

    .ms-Grid-row {
      margin: 0px;
    }

    .comment-details {
      //  background-color: rgb(245, 245, 245);
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      padding-bottom: 7px;
      //margin-left: 5px;

      .profile-name2 {
        color: #737373;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
        line-height: 0.8;
        margin-top: 10px;
        overflow: hidden;
        font-size: 14px;
        text-overflow: ellipsis;
      }

      .comment {
        margin: 0px;
        font-size: 14px;
        line-height: 1.34;
        overflow: hidden;
      }
    }

    .profile-pic2-c {
      padding-right: 0px;

      .profile-pic2 {
        height: 26px;
        width: 26px;
        border-radius: 50%;
        margin-right: 8px;
        padding: 0px;
        float: left;
      }
    }
  }



  .comment-info2 {
    padding: 0px !important;
    margin-bottom: 10px;

    .ms-Grid-row {
      margin: 0px;
    }

    .comment-details {
      background-color: rgb(245, 245, 245);
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      padding-bottom: 10px;

      .comment-in {
        outline: none;
        border: none;
        height: 100%;
        width: 100%;
        padding: 2px 4px;
        margin-top: 10px;
        background-color: rgb(245, 245, 245);
      }

      .comment {
        margin: 0px;
        font-size: 14px;
        line-height: 1.34;
      }
    }

    .profile-pic2-c {
      padding-right: 0px;

      .profile-pic2 {
        height: 36px;
        width: 36px;
        border-radius: 50%;
        margin-right: 8px;
        padding: 0px;
        float: left;
      }
    }
  }



  .comment-info3 {
    padding: 10px 0px 0px 0px;
    background-color: rgb(245, 245, 245);
    margin-bottom: 10px;
    position: absolute;
    display: inline-block;
    bottom: 0px;
    width: 94%;

    .ms-Grid-row {
      margin: 0px;
    }

    .comment-details {

      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      padding-bottom: 10px;
      //      left: 10px;

      .comment-in {
        outline: none;
        border: none;
        height: 100%;
        width: 100%;
        padding: 10px 4px 2px 4px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.3);
        //margin-top: 10px;
        background-color: rgb(245, 245, 245);
      }

      .comment {
        margin: 0px;
        font-size: 14px;
        line-height: 1.34;
      }
    }

    .profile-pic2-c {
      padding-right: 0px;

      .profile-pic2 {
        height: 26px;
        width: 26px;
        border-radius: 50%;
        margin-right: 8px;
        padding: 0px;
        float: left;
      }
    }
  }

  .all-comments {
    max-height: 450px;
    overflow-y: auto;
  }


  .all-comments1 {
    max-height: 450px;
    min-height: 450px;
    overflow-y: auto;
  }

  .view-more {
    margin: 0px;
    margin-left: 14px;
    color: #616770;
    font-size: 13px;
    cursor: pointer;
    font-weight: 500;
  }

  .login-warning-c {
    display: flow-root;

    .login-warning {
      margin-right: 10px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      float: right;
      padding: 4px;
    }
  }
}

.live-link {
  padding: 1px 0px 0px 0px !important;
  //margin: 0px !important;
  margin-left: 5px;
}

.live-gif {
  height: 38px;
  width: 100px;
}


.advertise {
  // border: 1px solid rgba(0, 0, 0, 0.2);
}


.news-feed-ad {
  //  max-height: 200px;
}

.live-a {
  margin-top: 7px !important;
}

.live-s {
  //margin-top: 16px;
  //  background: red;
  position: relative;
  padding: 10px 5px;
  font-weight: bold;
  color: white;
  animation: mymove 1s infinite;
}

@keyframes mymove {
  from {
    //  background: red;
    color: white
  }

  to {
    //    background: white;
    color: #E12829;
  }
}

.sp-video-player {
  position: relative;
  // border: 1px solid black;
  //  padding: 10px;
  //height: 200px;
  width: 100%;

  .sp-quality-o {
    font-size: 12px;
  }

  .sp-controls {
    position: absolute;
    z-index: 10;
    bottom: 0px;
    right: 0px;
  }
}


.sp-label {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
}



.sp-img34 {
  width: 100%;
  max-width: 100%;
}